export const SOCKET_IO_CONNECT = 'SOCKET_IO_CONNECT';
export const SOCKET_IO_DISCONNECT = 'SOCKET_IO_DISCONNECT';

interface ConnectSocketAction {
  type: typeof SOCKET_IO_CONNECT;
}

interface DisconnectSocketAction {
  type: typeof SOCKET_IO_DISCONNECT;
}

export type SocketActionTypes = ConnectSocketAction | DisconnectSocketAction;
