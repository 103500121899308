/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { config } from '../config';
import { useNewChatMessage, useSelectedChat, useUser } from '../store/hooks/hooks';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Mention, MentionsInput } from 'react-mentions';

import { buildTagUser, formatChatTime, generateRandomText, isImage } from '../utilities/common';
import { getSocketConnection } from '../services/Socket';
import { setSelectedChat } from '../store/user/actions';
import APIService from '../services/API';
import MentionInputStyle from '../utilities/MentionInputStyle';
import MentionDrStyle from '../utilities/MentionDrStyle';
import ImageFullScreen from '../components/ImageFullScreen';

import './SelectedChat.scss';
import avatar from '../statics/images/logo_wobg.png';
import quote from '../statics/images/quotes.svg';
import Reacted from '../pages/chat/Reacted';
import Reaction from '../pages/chat/Reaction';

const isMineMessage = (message: any, user: any) =>
  Number(user.id) === Number(message.user_id) && (message.user_type === 'web' || message.user_type === 'staff');

export default function SelectedChat() {
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const [replyMessage, setReplyMessage] = useState<any>({});
  const [highlight, setHighlight] = useState<string>('');
  const [mentionUsers, setMentionUsers] = useState<any[]>([]);
  const [fullScreenImg, setFullScreenImg] = useState<any>([]);
  const [imageClipboard, setImageClipboard] = useState<any>();
  const [imageClipboardURL, setImageClipboardURL] = useState<string>('');

  const selectedChat = useSelectedChat();
  const newChatMessage = useNewChatMessage();
  const messagesEndRef = useRef<any>();
  const needScrollBottomRef = useRef<boolean>(true);
  const user = useUser();
  const imageRef = useRef<any>();
  const fileRef = useRef<any>();
  const messagesRef = useRef<any[]>([]);
  const inputRef = useRef<any>();
  const videoRef = useRef<any>();
  const audioRef = useRef<any>();
  const chatInputGroupRef = useRef<any>();
  const dispatch = useDispatch();

  const handleImageClick = () => {
    imageRef.current.click();
  };

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const handleVideoClick = () => {
    videoRef.current.click();
  };

  const handleAudioClick = () => {
    audioRef.current.click();
  };

  const handleSelectImage = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && isImage(e.target.files[0].name)) {
      processFile(e.target.files, 'image');
    }
  };

  const handleSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      processFile(e.target.files, 'file');
    }
  };

  const handleSelectVideo = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      processFile(e.target.files, 'video');
    }
  };

  const handleSelectAudio = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      processFile(e.target.files, 'audio');
    }
  };

  const processFile = (files: any, type: string) => {
    if (files[0].size > 50 * 1024 * 1024) {
      alert('Giới hạn file 50 Mb');
      fileRef.current.value = '';
      imageRef.current.value = '';
    }
    APIService.uploadChatFile(
      selectedChat.id,
      generateRandomText(15),
      replyMessage?.message_id || '',
      type,
      files[0],
      (event: any) => {
        console.log(Math.round((100 * event.loaded) / event.total));
      },
    ).then((result: any) => {
      fileRef.current.value = '';
      imageRef.current.value = '';
      videoRef.current.value = '';
      audioRef.current.value = '';
    });
  };

  const handleFocus = (e: any) => {
    // getSocketConnection().emit('last-seen', {
    //   group_id: selectedChat.id,
    // });
    APIService.updateLastSeen(selectedChat.id);
    e.preventDefault();
  };

  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSendMessage();
      e.preventDefault();
    }
    if (isEmpty(e.target.value)) {
      setReplyMessage({});
    }
  };

  const handleSendMessage = () => {
    if (!isEmpty(message.trim())) {
      getSocketConnection().emit(
        'message',
        JSON.stringify({
          group_id: selectedChat.id,
          message_id: uuidv4(),
          reply_id: replyMessage?.message_id || '',
          message: message.trim(),
          type: 'text',
        }),
      );
      setMessage('');
      setReplyMessage({});
    }

    if (imageClipboard) {
      if (isImage(imageClipboard.name)) {
        processFile([imageClipboard], 'image');
      } else {
        processFile([imageClipboard], 'file');
      }
      setImageClipboard(null);
      setImageClipboardURL('');
    }
  };

  const handleChatNameClick = () => {
    dispatch(setSelectedChat({}));
  };

  const handleRemoveReplyMessage = () => {
    setReplyMessage({});
    setMessage('');
  };

  const handleRemoveCopyClipboardMessage = () => {
    setImageClipboard(null);
    setImageClipboardURL('');
  };

  const handlePaste = (e: any) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      setImageClipboard(fileObject);
      if (isImage(fileObject.name)) {
        const objectUrl = URL.createObjectURL(fileObject);
        setImageClipboardURL(objectUrl);
      } else {
        setImageClipboardURL('');
      }
    } else {
      setImageClipboard(null);
    }
  };

  const handleReact = async (messageID: number, reaction: string) => {
    const res = await APIService.saveChatMessageReaction(messageID, reaction);
    if (res.success) {
      res.data.message = buildTagUser(res.data.message);
      const index = findIndex(messages, { id: res.data.id });
      messages[index] = res.data;
      needScrollBottomRef.current = false;
      setMessages(cloneDeep(messages));
    }
  };

  const replyMessageTemplate = (message: any, closable: boolean) => {
    const handleReplyMessageClick = () => {
      const item = document.getElementById(message.message_id);
      if (item) {
        item.scrollIntoView({ behavior: 'smooth' });
        setHighlight(message.message_id);
        setTimeout(() => {
          setHighlight('');
        }, 3000);
      }
    };
    return message ? (
      <>
        <div className="reply-message" onClick={handleReplyMessageClick}>
          {closable && (
            <div className="close-reply-msg" onClick={handleRemoveReplyMessage}>
              <i className="pi pi-times"></i>
            </div>
          )}
          <div className="reply-content">
            <div>
              {closable && <span>"Trả lời: </span>}
              <b>{selectedUsers[message.from_id]?.full_name}</b>
            </div>
            {message.type === 'text' ? (
              <div>{message.message}</div>
            ) : message.type === 'image' ? (
              <div>
                <img
                  style={{ maxWidth: '30px' }}
                  src={config.apiServerUrl + message.url || message.base64 || message.message}
                  alt="image"
                />
                <span className="ml-2">[Hình ảnh]</span>
              </div>
            ) : (
              <div>{message.file_name} [Tập tin]</div>
            )}
          </div>
        </div>
        <br />
      </>
    ) : (
      <></>
    );
  };

  const imageClipboardTemplate = (message: any) => {
    return message ? (
      <>
        <div className="reply-message">
          <div className="close-reply-msg" onClick={handleRemoveCopyClipboardMessage}>
            <i className="pi pi-times"></i>
          </div>
          <div className="reply-content">
            {!isEmpty(imageClipboardURL) ? (
              <img className="preview-img" src={imageClipboardURL} alt="" />
            ) : (
              <h3>{imageClipboard.name}</h3>
            )}
          </div>
        </div>
        <br />
      </>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (!isEmpty(selectedChat)) {
      setSelectedUsers(
        selectedChat.users.reduce((res: any, item: any) => {
          res[item.id] = item;
          return res;
        }, {}),
      );
      setMentionUsers(
        selectedChat.users
          .filter((item: any) => !isEmpty(item.full_name) && item.id !== user.id && item.full_name !== user.full_name)
          .map((item: any) => {
            return {
              id: item.id,
              display: item.full_name,
            };
          }),
      );
      APIService.fetchChatMessages(selectedChat.id, 0).then((msgResult: any) => {
        if (msgResult.success) {
          const messages = msgResult.data.map((item: any) => {
            item.message = buildTagUser(item.message);
            return item;
          });
          needScrollBottomRef.current = true;
          setMessages(messages);
        } else {
          setMessages([]);
        }
      });
    }
  }, [selectedChat]);

  useEffect(() => {
    if (needScrollBottomRef.current) {
      messagesRef.current = messages;
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView();
      }, 300);
    }
  }, [messages]);

  useEffect(() => {
    if (!isEmpty(newChatMessage)) {
      const newMsg = JSON.parse(newChatMessage);
      const data = newMsg.message || {};
      if (selectedChat.id === data.group_id) {
        data.message = buildTagUser(data.message);
        const newMessages = messagesRef.current.concat(data);
        needScrollBottomRef.current = true;
        setMessages(newMessages);
      }
      // const index = findIndex(groupsRef.current, { id: data.group_id });
      // if (index >= 0) {
      //   const newGroups = cloneDeep(groupsRef.current);
      //   newGroups[index].messages = newGroups[index].messages.concat(data);
      //   newGroups[index].last_message = data.message;
      //   const user = find(newGroups[index].users, { user_id: `${data.user_id}` });
      //   if (user) {
      //     newGroups[index].last_sender = data.user_id;
      //     newGroups[index].last_sender_name = user?.full_name || '';
      //   }
      //   if (selectedGroupRef.current?.id !== data.group_id) {
      //     newGroups[index].new_message = (newGroups[index].new_message || 0) + 1;
      //   }
      //   setGroups(newGroups);
      // }
    }
  }, [newChatMessage]);

  return !isEmpty(selectedChat) ? (
    <div className="selected-chat-wrapper">
      <div className="s-chat-header">
        <div>
          <Link to={`${config.webChatUrl}/chat?id=${selectedChat.id}`} onClick={handleChatNameClick}>
            {selectedChat.name}
          </Link>
        </div>
        <div className="s-chat-close">
          <i className="pi pi-times" onClick={() => dispatch(setSelectedChat({}))}></i>
        </div>
      </div>
      <div className="s-chat-content">
        <ul>
          {messages.map((message: any, index: number) => {
            const handleDownloadFile = () => {
              fetch(config.apiServerUrl + message.url)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.download = message.file_name;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                })
                .catch(() => alert('oh no!'));
            };

            const handleReplyMessage = () => {
              setReplyMessage(message);
              setMessage(`@${selectedUsers[message.from_id]?.full_name}: `);
              inputRef.current.focus();
              setTimeout(() => {
                chatInputGroupRef.current.scrollTop = chatInputGroupRef.current.scrollHeight;
              }, 100);
            };

            return message.type === 'system' || message.type === 'system-time' ? (
              <li>
                {message.type === 'system' && <div className="system-message">{message.message}</div>}
                {message.type === 'system-time' && (
                  <div className="system-message">{formatChatTime(message.message)}</div>
                )}
              </li>
            ) : message.is_deleted ? (
              <li className="" ref={messagesEndRef}>
                <div
                  className={classNames(`message-avatar ${Number(message.user_id)} ${user.id}`, {
                    mine: isMineMessage(message, user),
                    other: !isMineMessage(message, user),
                  })}
                >
                  {!isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                  <div
                    className={classNames('message-content', {
                      'full-width': isMineMessage(message, user),
                    })}
                  >
                    <div
                      id={message.message_id}
                      className={classNames('message', {
                        'my-message float-right': isMineMessage(message, user),
                        'other-message': !isMineMessage(message, user),
                        highlight: !isEmpty(highlight) && message.message_id === highlight,
                      })}
                      onDoubleClick={handleReplyMessage}
                    >
                      {!isEmpty(message.reply_id) && replyMessageTemplate(message.reply_message, false)}
                      {!isMineMessage(message, user) &&
                        (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id)) && (
                          <div className="message-full-name">
                            {selectedUsers[message.from_id]?.full_name || 'Đã rời khỏi nhóm'}
                          </div>
                        )}
                      <div>
                        <small>
                          <i>Tin nhắn đã bị thu hồi</i>
                        </small>
                      </div>
                      <span className="message-data-time">{moment(message.created_at).format('HH:mm')}</span>
                    </div>
                  </div>
                  {isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                </div>
              </li>
            ) : message.type === 'image' || message.type === 'video' || message.type === 'audio' ? (
              <li className="" ref={messagesEndRef}>
                <div
                  className={classNames(`message-avatar ${Number(message.user_id)} ${user.id}`, {
                    mine: isMineMessage(message, user),
                    other: !isMineMessage(message, user),
                  })}
                >
                  {!isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                  <div
                    className={classNames('message-content', {
                      'full-width': isMineMessage(message, user),
                    })}
                  >
                    <div
                      id={message.message_id}
                      className={classNames('message', {
                        'my-message float-right': isMineMessage(message, user),
                        'other-message': !isMineMessage(message, user),
                        highlight: !isEmpty(highlight) && message.message_id === highlight,
                      })}
                      onDoubleClick={handleReplyMessage}
                    >
                      {!isEmpty(message.reply_id) && replyMessageTemplate(message.reply_message, false)}
                      {!isMineMessage(message, user) &&
                        (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id)) && (
                          <div className="message-full-name">
                            {selectedUsers[message.from_id]?.full_name || 'Đã rời khỏi nhóm'}
                          </div>
                        )}
                      {message.type === 'image' && (
                        <div>
                          <img
                            onClick={() =>
                              setFullScreenImg([
                                {
                                  url: config.apiServerUrl + message.url || message.base64 || message.message,
                                  title: 'Hình ảnh',
                                },
                              ])
                            }
                            style={{ maxWidth: '200px', cursor: 'pointer' }}
                            src={config.apiServerUrl + message.url || message.base64 || message.message}
                            alt="image"
                          />
                        </div>
                      )}
                      {message.type === 'video' && (
                        <div>
                          <video style={{ maxWidth: '200px', cursor: 'pointer' }} controls>
                            <source src={config.apiServerUrl + message.url} />
                          </video>
                        </div>
                      )}
                      {message.type === 'audio' && (
                        <div>
                          <audio style={{ maxWidth: '200px', cursor: 'pointer' }} controls>
                            <source src={config.apiServerUrl + message.url} type="audio/ogg" />
                            <source src={config.apiServerUrl + message.url} type="audio/mpeg" />
                          </audio>
                        </div>
                      )}
                      <span className="message-data-time">{moment(message.created_at).format('HH:mm')}</span>
                      <div className="quotes-group">
                        <span>
                          <img src={quote} alt="quote" onClick={handleReplyMessage} title="Trả lời" />
                        </span>
                      </div>
                      <Reacted message={message} />
                      <Reaction messageID={message.message_id} onReact={handleReact} />
                    </div>
                  </div>
                  {isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                </div>
              </li>
            ) : message.type === 'video' ? (
              <li className="" ref={messagesEndRef}>
                <div
                  className={classNames(`message-avatar ${Number(message.user_id)} ${user.id}`, {
                    mine: isMineMessage(message, user),
                    other: !isMineMessage(message, user),
                  })}
                >
                  {!isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                  <div
                    className={classNames('message-content', {
                      'full-width': isMineMessage(message, user),
                    })}
                  >
                    <div
                      id={message.message_id}
                      className={classNames('message', {
                        'my-message float-right': isMineMessage(message, user),
                        'other-message': !isMineMessage(message, user),
                        highlight: !isEmpty(highlight) && message.message_id === highlight,
                      })}
                      onDoubleClick={handleReplyMessage}
                    >
                      {!isEmpty(message.reply_id) && replyMessageTemplate(message.reply_message, false)}
                      {!isMineMessage(message, user) &&
                        (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id)) && (
                          <div className="message-full-name">
                            {selectedUsers[message.from_id]?.full_name || 'Đã rời khỏi nhóm'}
                          </div>
                        )}
                      <div>
                        {/* <img
                          onClick={() =>
                            setFullScreenImg(config.apiServerUrl + message.url || message.base64 || message.message)
                          }
                          style={{ maxWidth: '200px', cursor: 'pointer' }}
                          src={config.apiServerUrl + message.url || message.base64 || message.message}
                          alt="image"
                        /> */}
                        <video style={{ maxWidth: '200px', cursor: 'pointer' }} controls>
                          <source src={config.apiServerUrl + message.url} />
                        </video>
                      </div>
                      <span className="message-data-time">{moment(message.created_at).format('HH:mm')}</span>
                      <div className="quotes-group">
                        <span>
                          <img src={quote} alt="quote" onClick={handleReplyMessage} title="Trả lời" />
                        </span>
                      </div>
                      <Reacted message={message} />
                      <Reaction messageID={message.message_id} onReact={handleReact} />
                    </div>
                  </div>
                  {isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                </div>
              </li>
            ) : message.type === 'file' ? (
              <li className="" ref={messagesEndRef}>
                <div
                  className={classNames(`message-avatar ${Number(message.user_id)} ${user.id}`, {
                    mine: isMineMessage(message, user),
                    other: !isMineMessage(message, user),
                  })}
                >
                  {!isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                  <div
                    className={classNames('message-content', {
                      'full-width': isMineMessage(message, user),
                    })}
                  >
                    <div
                      id={message.message_id}
                      className={classNames('message', {
                        'my-message float-right': isMineMessage(message, user),
                        'other-message': !isMineMessage(message, user),
                        highlight: !isEmpty(highlight) && message.message_id === highlight,
                      })}
                      onDoubleClick={handleReplyMessage}
                    >
                      {!isEmpty(message.reply_id) && replyMessageTemplate(message.reply_message, false)}
                      {!isMineMessage(message, user) &&
                        (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id)) && (
                          <div className="message-full-name">
                            {selectedUsers[message.from_id]?.full_name || 'Đã rời khỏi nhóm'}
                          </div>
                        )}
                      <div className="message-flex" style={{ maxWidth: '200px' }}>
                        <div>
                          <span className="pi pi-file" />
                          <div>
                            <div className="wrap-text">
                              <a className="p-link">{message.file_name}</a>
                            </div>
                            <small>
                              {message.size > 1024 * 1024
                                ? `${(message.size / 1024 / 1024).toFixed(2)} MB`
                                : `${(message.size / 1024).toFixed(2)} KB`}
                            </small>
                          </div>
                        </div>
                        <div>
                          <Button
                            className="p-button p-button-transparent"
                            icon="pi pi-download"
                            onClick={handleDownloadFile}
                          />
                        </div>
                      </div>
                      <span className="message-data-time">{moment(message.created_at).format('HH:mm')}</span>
                      <div className="quotes-group">
                        <span>
                          <img src={quote} alt="quote" onClick={handleReplyMessage} title="Trả lời" />
                        </span>
                      </div>
                      <Reacted message={message} />
                      <Reaction messageID={message.message_id} onReact={handleReact} />
                    </div>
                  </div>
                  {isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                </div>
              </li>
            ) : (
              <li className="" ref={messagesEndRef}>
                <div
                  className={classNames(`message-avatar ${Number(message.user_id)} ${user.id}`, {
                    mine: isMineMessage(message, user),
                    other: !isMineMessage(message, user),
                  })}
                >
                  {!isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                  <div
                    className={classNames('message-content', {
                      'full-width': isMineMessage(message, user),
                    })}
                  >
                    <div
                      id={message.message_id}
                      className={classNames('message', {
                        'my-message float-right': isMineMessage(message, user),
                        'other-message': !isMineMessage(message, user),
                        highlight: !isEmpty(highlight) && message.message_id === highlight,
                      })}
                      onDoubleClick={handleReplyMessage}
                    >
                      {!isEmpty(message.reply_id) && replyMessageTemplate(message.reply_message, false)}
                      {!isMineMessage(message, user) &&
                        (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id)) && (
                          <div className="message-full-name">
                            {selectedUsers[message.from_id]?.full_name || 'Đã rời khỏi nhóm'}
                          </div>
                        )}
                      <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
                      <span className="message-data-time">{moment(message.created_at).format('HH:mm')}</span>
                      <div className="quotes-group">
                        <span>
                          <img src={quote} alt="quote" onClick={handleReplyMessage} title="Trả lời" />
                        </span>
                      </div>
                      <Reacted message={message} />
                      <Reaction messageID={message.message_id} onReact={handleReact} />
                    </div>
                  </div>
                  {isMineMessage(message, user) &&
                    (index === 0 || (index > 0 && messages[index - 1].user_id !== message.user_id) ? (
                      <div className="chat-avatar-group">
                        <img src={avatar} className="chat-avatar" alt="avatar" />
                      </div>
                    ) : (
                      <div className="empty-avatar">&nbsp;</div>
                    ))}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="d-flex flex-row align-items-end s-chat-input" ref={chatInputGroupRef}>
        <div className="chat-message-input">
          <div className="cs-file-input">
            <input ref={imageRef} type="file" className="d-none" accept="image/*" onChange={handleSelectImage} />
            <input
              ref={fileRef}
              type="file"
              className="d-none"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onChange={handleSelectFile}
            />
            <input ref={videoRef} type="file" className="d-none" accept="video/mp4" onChange={handleSelectVideo} />
            <input
              ref={audioRef}
              type="file"
              className="d-none"
              accept="audio/mp3,audio/amr"
              onChange={handleSelectAudio}
            />
            <i className="pi pi-image" onClick={handleImageClick}></i>
            <i className="pi pi-paperclip ml-8" onClick={handleFileClick}></i>
            <i className="pi pi-video ml-8" onClick={handleVideoClick}></i>
            <i className="pi pi-dollar ml-8" onClick={handleAudioClick}></i>
          </div>
          <div className="cs-message-input">
            <div className="scrollbar-container cs-message-input__content-editor-container ps" onPaste={handlePaste}>
              {imageClipboard && imageClipboardTemplate(imageClipboard)}
              {!isEmpty(replyMessage) && replyMessageTemplate(replyMessage, true)}
              {/* <textarea
                ref={inputRef}
                name="message-to-send"
                id="message-to-send"
                placeholder="Type your message"
                rows={1}
                value={message}
                onFocus={handleFocus}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
              ></textarea> */}
              <MentionsInput
                inputRef={inputRef}
                id="message-to-send"
                value={message}
                onFocus={handleFocus}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                placeholder="Nhập @, tin nhắn tới nhóm"
                suggestionsPortalHost={document.body}
                allowSuggestionsAboveCursor={true}
                forceSuggestionsAboveCursor={true}
                style={MentionInputStyle}
              >
                <Mention
                  trigger="@"
                  markup="{{__id__@__display__}}"
                  displayTransform={(id, display) => `@${display}`}
                  appendSpaceOnAdd={true}
                  data={mentionUsers}
                  style={MentionDrStyle}
                />
              </MentionsInput>
              <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}></div>
              </div>
              <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }}></div>
              </div>
            </div>
            <button
              disabled={isEmpty(message)}
              className="p-button p-component p-button-text-only"
              onClick={handleSendMessage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#6ea9d7"
                className="bi bi-send"
                viewBox="0 0 16 16"
              >
                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ImageFullScreen urls={fullScreenImg} startIndex={0} onHide={() => setFullScreenImg([])} />
    </div>
  ) : (
    <></>
  );
}
