import React from 'react';

interface Props {
  messageID: number;
  onReact: (messageID: number, reaction: string) => void;
}
export default function Reaction(props: Props) {
  return (
    <div className="reaction-icon">
      <span className="pi v-like" onClick={() => props.onReact(props.messageID, ':like:')}></span>
      <span className="pi v-love" onClick={() => props.onReact(props.messageID, ':love:')}></span>
      <span className="pi v-haha" onClick={() => props.onReact(props.messageID, ':haha:')}></span>
      <span className="pi v-wow" onClick={() => props.onReact(props.messageID, ':wow:')}></span>
      <span className="pi v-sad" onClick={() => props.onReact(props.messageID, ':sad:')}></span>
      <span className="pi v-angry" onClick={() => props.onReact(props.messageID, ':angry:')}></span>
    </div>
  );
}
