import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { setUser } from '../store/user/actions';
import User from '../models/user';
import { useUser } from '../store/hooks/hooks';
import AccountService from '../services/Account';

const redirectToHome = () => {
  window.location.href = '/';
  return <Redirect to="/" />;
};

function LogoutRoute(props: RouteProps) {
  const { render, ...rest } = props;
  const dispatch = useDispatch();
  const user = useUser();

  useEffect(() => {
    if (user.isLoggedIn()) {
      AccountService.logout();
      dispatch(setUser(new User()));
    }
  }, [user]);

  return <Route {...rest} render={redirectToHome} />;
}

export default LogoutRoute;
