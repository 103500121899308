import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../../config';
import CONSTANTS from '../../constants/constant';
import User from '../../models/user';

axios.defaults.baseURL = config.apiServerUrl;
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403 || error.response.status === 406)
    ) {
      if (!window.location.href.includes('/logout')) {
        window.location.href = '/logout';
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export const setAxiosHeaders = (token: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const getUserStorage = () => {
  if (localStorage.getItem(CONSTANTS.STORAGE_KEY.DRIVER_USER)) {
    const user = JSON.parse(localStorage.getItem(CONSTANTS.STORAGE_KEY.DRIVER_USER) || '{}');
    return user.user;
  }

  return undefined;
};

export const setUserStorage = (newUser: User) => {
  if (newUser.token) {
    const userStorage = {
      user: newUser,
    };

    localStorage.setItem(CONSTANTS.STORAGE_KEY.DRIVER_USER, JSON.stringify(userStorage));
  } else {
    localStorage.removeItem(CONSTANTS.STORAGE_KEY.DRIVER_USER);
  }
};

export const calculateWorkingHours = (date: any, fromTime: any, toTime: any) => {
  const beginWorkingDate = moment.tz(date, 'Asia/Ho_Chi_Minh').set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  const beginLunch = moment.tz(date, 'Asia/Ho_Chi_Minh').set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  const endLunch = moment.tz(date, 'Asia/Ho_Chi_Minh').set({ hour: 13, minute: 0, second: 0, millisecond: 0 });
  // const beginDinner = moment.tz(date, 'Asia/Ho_Chi_Minh').set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  // const endDinner = moment.tz(date, 'Asia/Ho_Chi_Minh').set({ hour: 17, minute: 30, second: 0, millisecond: 0 });

  fromTime = moment.tz(fromTime, 'Asia/Ho_Chi_Minh');
  toTime = moment.tz(toTime, 'Asia/Ho_Chi_Minh');
  if (beginWorkingDate.isAfter(fromTime)) {
    fromTime = beginWorkingDate;
  }
  let hours = 0;

  if (
    beginLunch.isAfter(fromTime) &&
    beginLunch.isAfter(toTime) &&
    moment.duration(toTime.diff(fromTime)).asMinutes() > 0
  ) {
    // Working morning only
    hours = moment.duration(toTime.diff(fromTime)).asMinutes();
    return Math.floor((hours * 10) / 60) / 10;
  }

  if (
    beginLunch.isSameOrAfter(fromTime) &&
    beginLunch.isSameOrBefore(toTime) &&
    moment.duration(beginLunch.diff(fromTime)).asMinutes() > 0
  ) {
    hours += moment.duration(beginLunch.diff(fromTime)).asMinutes();
    fromTime = endLunch;
  }

  // if (
  //   beginDinner.isSameOrAfter(fromTime) &&
  //   beginDinner.isSameOrAfter(toTime) &&
  //   moment.duration(toTime.diff(fromTime)).asMinutes() > 0
  // ) {
  //   hours += moment.duration(toTime.diff(fromTime)).asMinutes();
  //   fromTime = toTime;
  // }

  // if (
  //   beginDinner.isSameOrAfter(fromTime) &&
  //   beginDinner.isSameOrBefore(toTime) &&
  //   moment.duration(beginDinner.diff(fromTime)).asMinutes() > 0
  // ) {
  //   hours += moment.duration(beginDinner.diff(fromTime)).asMinutes();
  //   fromTime = endDinner;
  // }

  // if (
  //   beginDinner.isSameOrAfter(fromTime) &&
  //   endDinner.isSameOrBefore(toTime) &&
  //   moment.duration(toTime.diff(endDinner)).asMinutes() > 0
  // ) {
  //   hours += moment.duration(toTime.diff(endDinner)).asMinutes();
  //   fromTime = endDinner;
  // }

  if (
    // beginDinner.isSameOrBefore(fromTime) &&
    // endDinner.isSameOrBefore(toTime) &&
    moment.duration(toTime.diff(fromTime)).asMinutes() > 0
  ) {
    // Ca toi only
    hours += moment.duration(toTime.diff(fromTime)).asMinutes();
  }
  let wHour = Math.floor((hours * 10) / 60) / 10;
  return wHour <= 9 ? wHour : 9;
};
