import { isEmpty } from 'lodash';
import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useUser } from '../store/hooks/hooks';
import qs from 'query-string';

interface Props extends RouteProps {
  admin?: boolean;
  role: string;
}

const redirectToLogin = (comProps: RouteComponentProps) => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: comProps.location },
      }}
    />
  );
};

const redirectToHome = (comProps: RouteComponentProps) => {
  return (
    <Redirect
      to={{
        pathname: '/tai-xe',
        state: { from: comProps.location },
      }}
    />
  );
};

function PrivateRoute(props: Props) {
  const { render, admin, ...rest } = props;
  const user = useUser();

  const renderRedirect = () => {
    const params: any = qs.parse(window.location.search);
    if (params.imei) {
      localStorage.setItem('imei', params.imei || '');
    }

    if (user.isLoggedIn()) {
      if (isEmpty(props.role) || user.hasRole(props.role)) {
        return render;
      } else {
        return redirectToHome;
      }
    } else {
      return redirectToLogin;
    }
  };

  return <Route {...rest} render={renderRedirect()} />;
}

export default PrivateRoute;
