import User from '../../models/user';

export const SET_USER = 'SET_USER';
export const SET_UNREAD_MESSAGE_COUNT = 'SET_UNREAD_MESSAGE_COUNT';
export const SET_CHAT_GROUPS = 'SET_CHAT_GROUPS';
export const SET_SELECTED_CHAT = 'SET_SELECTED_CHAT';
export const SET_NEW_CHAT_MESSAGE = 'SET_NEW_CHAT_MESSAGE';
export const SET_UPDATE_CHAT_MESSAGE = 'SET_UPDATE_CHAT_MESSAGE';
export const SET_INSTANTS = 'SET_INSTANTS';
export const SET_INSTANT_SUGGESTIONS = 'SET_INSTANT_SUGGESTIONS';
export const SET_OWNER_ASSIGNMENTS = 'SET_OWNER_ASSIGNMENTS';
export const SET_MATCHING_ORDER = 'SET_MATCHING_ORDER';
export const SET_MATCHING_OWNER = 'SET_MATCHING_OWNER';

export interface UserState {
  user: User;
  unreadMessageCount: any;
  chatGroups: any[];
  selectedChat: any;
  newChatMessage: string;
  updateChatMessage: string;
  instants: any[];
  instantSuggestions: any;
  ownerAssignments: string[];
  matchingOwner: any;
  matchingOrder: any;
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: User;
}

interface UnreadMessageCountAction {
  type: typeof SET_UNREAD_MESSAGE_COUNT;
  payload: any;
}

interface ChatGroupsAction {
  type: typeof SET_CHAT_GROUPS;
  payload: any[];
}

interface NewChatMessageAction {
  type: typeof SET_NEW_CHAT_MESSAGE;
  payload: any;
}

interface UpdateChatMessageAction {
  type: typeof SET_UPDATE_CHAT_MESSAGE;
  payload: any;
}

interface SelectedChat {
  type: typeof SET_SELECTED_CHAT;
  payload: any;
}

interface InstantsAction {
  type: typeof SET_INSTANTS;
  payload: any;
}

interface InstantSuggestionsAction {
  type: typeof SET_INSTANT_SUGGESTIONS;
  payload: any;
}

interface OwnerAssignmentsAction {
  type: typeof SET_OWNER_ASSIGNMENTS;
  payload: any;
}

interface MatchingOrderAction {
  type: typeof SET_MATCHING_ORDER;
  payload: any;
}

interface MatchingOwnerAction {
  type: typeof SET_MATCHING_OWNER;
  payload: any;
}

export type UserActionTypes =
  | SetUserAction
  | UnreadMessageCountAction
  | ChatGroupsAction
  | NewChatMessageAction
  | UpdateChatMessageAction
  | SelectedChat
  | InstantsAction
  | InstantSuggestionsAction
  | OwnerAssignmentsAction
  | MatchingOrderAction
  | MatchingOwnerAction;
