import User from '../../models/user';
import {
  SET_CHAT_GROUPS,
  SET_INSTANTS,
  SET_NEW_CHAT_MESSAGE,
  SET_SELECTED_CHAT,
  SET_UNREAD_MESSAGE_COUNT,
  SET_USER,
  SET_INSTANT_SUGGESTIONS,
  SET_OWNER_ASSIGNMENTS,
  UserActionTypes,
  UserState,
  SET_MATCHING_ORDER,
  SET_MATCHING_OWNER,
  SET_UPDATE_CHAT_MESSAGE,
} from './types';

const initialState: UserState = {
  user: new User(),
  unreadMessageCount: {},
  chatGroups: [],
  instants: [],
  newChatMessage: '',
  updateChatMessage: '',
  selectedChat: {},
  instantSuggestions: {},
  ownerAssignments: [],
  matchingOrder: {},
  matchingOwner: {},
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SET_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadMessageCount: action.payload,
      };
    }
    case SET_CHAT_GROUPS: {
      return {
        ...state,
        chatGroups: action.payload,
      };
    }
    case SET_NEW_CHAT_MESSAGE: {
      return {
        ...state,
        newChatMessage: action.payload,
      };
    }
    case SET_UPDATE_CHAT_MESSAGE: {
      return {
        ...state,
        updateChatMessage: action.payload,
      };
    }
    case SET_SELECTED_CHAT: {
      return {
        ...state,
        selectedChat: action.payload,
      };
    }
    case SET_INSTANTS: {
      return {
        ...state,
        instants: action.payload,
      };
    }
    case SET_INSTANT_SUGGESTIONS: {
      return {
        ...state,
        instantSuggestions: action.payload,
      };
    }
    case SET_OWNER_ASSIGNMENTS: {
      return {
        ...state,
        ownerAssignments: action.payload,
      };
    }
    case SET_MATCHING_ORDER: {
      return {
        ...state,
        matchingOrder: action.payload,
      };
    }
    case SET_MATCHING_OWNER: {
      return {
        ...state,
        matchingOwner: action.payload,
      };
    }
    default:
      return state;
  }
}
