import React from 'react';
import qs from 'query-string';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { useUser } from '../store/hooks/hooks';
import Login from '../pages/Login';
import CONSTANTS from '../constants/constant';

function LoginRoute(props: RouteProps) {
  const { render, ...rest } = props;
  const user = useUser();

  const renderLogin = (comProps: RouteComponentProps) => {
    const params = qs.parse(window.location.search);
    if (params.redirect) {
      if (user.token) {
        window.location.href = `${params.redirect}?token=${user.token}`;
      } else {
        localStorage.setItem(CONSTANTS.STORAGE_KEY.REDIRECT, params.redirect as string);
        return render ? render(comProps) : <Login {...comProps} />;
      }
    } else {
      if (user.token) {
        comProps.history.push('/');
      } else {
        return render ? render(comProps) : <Login {...comProps} />;
      }
    }
  };

  return <Route {...rest} render={renderLogin} />;
}

export default LoginRoute;
