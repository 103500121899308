/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  message: any;
}
export default function Reacted(props: Props) {
  const [reactions, setReactions] = React.useState<any>([]);
  useEffect(() => {
    if (props.message.reactions) {
      const rcts = props.message.reactions.reduce((acc: any, cur: any) => {
        const index = acc.findIndex((item: any) => item.react === cur.react);
        if (index >= 0) {
          acc[index].react_by.push(cur.react_by);
          acc[index].react_by_name.push(cur.react_by_name);
        } else {
          acc.push({
            react: cur.react,
            react_by: [cur.react_by],
            react_by_name: [cur.react_by_name],
          });
        }
        return acc;
      }, []);
      setReactions(rcts);
    }
  }, [props.message]);
  return (
    <div className="reacted-icons">
      {reactions.map((r: any) => (
        <span key={`r-${r.react_by.join('-')}-${r.react.split(':')[1]}`}>
          <ReactTooltip id={`react-tooltip-${r.react_by.join('-')}-${r.react.split(':')[1]}`}>
            {/* {`${r.react_by_name.join('<br>')}`} */}
            {r.react_by_name.map((name: any) => {
              return <div key={`r-${r.react_by.join('-')}-${r.react.split(':')[1]}-${name}`}>{name}</div>;
            })}
          </ReactTooltip>
          <span
            className={`pi v-${r.react.split(':')[1]} text-pointer`}
            data-tip
            data-for={`react-tooltip-${r.react_by.join('-')}-${r.react.split(':')[1]}`}
          ></span>
          <small>{r.react_by.length > 1 ? r.react_by.length : ''}</small>
        </span>
      ))}
    </div>
  );
}
