import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, withRouter } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { OverlayPanel } from 'primereact/overlaypanel';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Col, Row } from 'react-flexbox-grid';
import { FormattedNumber } from 'react-intl';

import {
  useChatGroups,
  useMatchingOrder,
  useMatchingOwner,
  useNewChatMessage,
  useSelectedChat,
  useUnreadMessageCount,
  useUser,
} from '../store/hooks/hooks';
import CONSTANTS from '../constants/constant';
import { setUnreadMessageCount } from '../store/user/actions';
import InfiniteList from '../pages/chat/InfiniteList';
import { buildTagUser, trimLastMessage } from '../utilities/common';
import API from '../services/API';

import './Header.scss';
import UserProfileIcon from '../statics/images/profile_icon.png';
import Logo from '../statics/images/logo_wobg.png';
import avatar from '../statics/images/logo_wobg.png';
import AudioMessage from '../statics/sounds/new-mess.mp3';
import { config } from '../config';

function Header() {
  const [chatTab, setChatTab] = useState<string>('internal');
  const [groupPage, setGroupPage] = useState<number>(0);
  const [canNextPage, setCanNextPage] = useState<boolean>(true);
  const [unread, setUnread] = useState<number>(0);
  const [visibleNotification, setVisibleNotification] = useState<boolean>(false);
  const [matchingOrderNoti, setMatchingOrderNoti] = useState<any>([]);
  const [visibleMatchingOwner, setVisibleMatchingOwner] = useState<boolean>(false);
  const [visibleMatchingOrder, setVisibleMatchingOrder] = useState<boolean>(false);

  const userProfileRef = useRef<OverlayPanel>(null);
  const chatGroupsRef = useRef<OverlayPanel>(null);

  const user = useUser();
  const location = useLocation();
  const { chatGroups, setChatGroups } = useChatGroups();
  const dispatch = useDispatch();
  const newChatMessage = useNewChatMessage();
  const unreadMessage = useUnreadMessageCount();
  const matchingOwner = useMatchingOwner();
  const matchingOrder = useMatchingOrder();
  const selectedChat = useSelectedChat();
  const selectedChatRef = useRef<any>();

  const handleShowProfile = (e: any) => {
    if (userProfileRef.current) {
      userProfileRef.current.toggle(e);
    }
  };

  const handleShowChatGroup = (e: any) => {
    if (chatGroupsRef.current) {
      chatGroupsRef.current.toggle(e);
    }
  };

  const handleHideProfile = () => {
    if (userProfileRef.current) {
      userProfileRef.current.hide();
    }
    if (chatGroupsRef.current) {
      chatGroupsRef.current.hide();
    }
  };

  const getMatchingOrders = async () => {
    const mOrderResult = await API.fetchMatchingOrders();
    if (mOrderResult.success) {
      setMatchingOrderNoti(mOrderResult.data);
    }
    return mOrderResult;
  };

  const handleShowNotification = async () => {
    const res = await getMatchingOrders();
    if (res.success && !isEmpty(res.data)) {
      setVisibleNotification(true);
    }
  };

  const handleHideNotification = () => {
    setVisibleNotification(false);
  };

  const handleHideMatchingOrder = () => {
    setVisibleMatchingOrder(false);
  };

  const handleChatTabClick = (tab: string) => {
    setChatTab(tab);
    setGroupPage(0);
    setChatGroups([]);
    setCanNextPage(true);
  };

  const handleHideMatchingOwner = () => {
    setVisibleMatchingOwner(false);
  };

  const handleChatGroupLoad = (load: boolean) => {
    if (load && canNextPage) {
      setGroupPage(groupPage + 1);

      API.fetchChatGroups(groupPage + 1, '', chatTab, '', false).then((result) => {
        if (result.success) {
          if (isEmpty(result.data)) {
            setCanNextPage(false);
          }
          result.data.forEach((item: any) => {
            item.last_message = buildTagUser(item.last_message);
            item.last_message = trimLastMessage(item.last_message);
          });
          const data = chatGroups.concat(result.data);
          setChatGroups(data);
        }
      });
      API.fetchUnreadMessageCount().then((result: any) => {
        if (result.success) {
          dispatch(setUnreadMessageCount(result.data));
        }
      });
    }
  };

  const getTransportType = (owner: any) => {
    let data = '';
    owner.vehicle_type = owner.vehicle_type || '[]';
    owner.container_type = owner.container_type || '[]';
    owner.vehicle_length = owner.vehicle_length || '[]';

    owner.vehicle_type =
      !isEmpty(owner.vehicle_type) && typeof owner.vehicle_type === 'string' ? JSON.parse(owner.vehicle_type) : [];
    owner.container_type =
      !isEmpty(owner.container_type) && typeof owner.container_type === 'string'
        ? JSON.parse(owner.container_type)
        : [];
    owner.vehicle_length =
      !isEmpty(owner.vehicle_length) && typeof owner.vehicle_length === 'string'
        ? JSON.parse(owner.vehicle_length)
        : [];
    if (isEmpty(owner.vehicle_type) && isEmpty(owner.container_type) && isEmpty(owner.vehicle_length)) {
      data = owner.transport_type;
    } else {
      if (!isEmpty(owner.vehicle_type)) {
        data += owner.vehicle_type.join(', ');
      }
      if (!isEmpty(owner.container_type)) {
        data += owner.container_type.join(', ');
      }
      if (!isEmpty(owner.vehicle_length)) {
        data += owner.vehicle_length.join(', ');
      }
    }
    return data;
  };

  useEffect(() => {
    if (!isEmpty(newChatMessage) && location.pathname === '/chat') {
      // const data = newMsg.message || {};
      // if (selectedChatRef?.current?.id !== data.group_id) {
      // if (newMsg.type !== 'system-adv' && newMsg.type !== 'system' && newMsg.type !== 'system-time') {
      // const audioNotification = new Audio(AudioMessage);
      // audioNotification.play().then((r) => {});
      // }
      // setVisibleChatBadge(true);
      // }
      const newMsg = JSON.parse(newChatMessage);
      const data: any = newMsg.message || {};
      if (data.type !== 'system-adv' && data.type !== 'system' && data.type !== 'system-time') {
        const audioNotification = new Audio(AudioMessage);
        audioNotification.play().then((r) => {});
      }
    }
  }, [newChatMessage]);

  useEffect(() => {
    selectedChatRef.current = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    if (!isEmpty(matchingOwner)) {
      setVisibleMatchingOwner(true);
    }
  }, [matchingOwner]);

  useEffect(() => {
    if (!isEmpty(matchingOrder)) {
      setVisibleMatchingOrder(true);
    }
  }, [matchingOrder]);

  useEffect(() => {
    if (!isEmpty(unreadMessage)) {
      let totalNewMsg = 0;
      for (const groupID of Object.keys(unreadMessage.internal)) {
        totalNewMsg += unreadMessage.internal[groupID];
      }
      for (const groupID of Object.keys(unreadMessage.driver)) {
        totalNewMsg += unreadMessage.driver[groupID];
      }
      for (const groupID of Object.keys(unreadMessage.ctv)) {
        totalNewMsg += unreadMessage.ctv[groupID];
      }
      for (const groupID of Object.keys(unreadMessage.customer)) {
        totalNewMsg += unreadMessage.customer[groupID];
      }
      for (const groupID of Object.keys(unreadMessage.staff)) {
        totalNewMsg += unreadMessage.staff[groupID];
      }
      setUnread(totalNewMsg);
    }
  }, [unreadMessage]);

  useEffect(() => {
    if (!isEmpty(chatTab)) {
      handleChatGroupLoad(true);
    }
  }, [chatTab]);

  useEffect(() => {
    getMatchingOrders();
  }, []);

  return (
    <Navbar className="header" expand="lg">
      <Navbar.Brand href="/">
        <img src={Logo} alt="logo" className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={location.pathname}>
          <Nav.Link href="/tai-xe">DS TÀI XẾ</Nav.Link>
          <Nav.Link href="/xe-dat-hang">XE ĐẶT HÀNG</Nav.Link>
          <Nav.Link href="/chanh">DS CHÀNH</Nav.Link>
          <Nav.Link href="/chanh-dat-hang">CHÀNH ĐẶT HÀNG</Nav.Link>
          <Nav.Link href="/chu-hang">DS CHỦ HÀNG</Nav.Link>
          <Nav.Link href="/chu-dat-hang">CHỦ ĐẶT HÀNG</Nav.Link>
          <Nav.Link href="/van-hanh">VẬN HÀNH ĐƠN HÀNG</Nav.Link>
          <Nav.Link href="/hop-dong">HỢP ĐỒNG ĐƠN HÀNG</Nav.Link>
          <Nav.Link href="/thanh-toan">THANH TOÁN CHUYỂN KHOẢN</Nav.Link>
          <Nav.Link href="/kiem-soat-dh">KIỂM SOÁT ĐƠN HÀNG</Nav.Link>
          <Nav.Link href="/tham-khao-gia">THAM KHẢO GIÁ</Nav.Link>
          <Nav.Link href="/don-hang-nhanh">ĐƠN HÀNG NHANH</Nav.Link>
          {user.hasRole(CONSTANTS.SYSTEM_ROLES.QUAN_TRI_HE_THONG) && (
            <Nav.Link href="/don-hang-nhanh-2">ĐƠN HÀNG NHANH (TEST)</Nav.Link>
          )}
          <Nav.Link href="/ky-quy">KÝ QUỸ</Nav.Link>
          <Nav.Link href="/bao-hiem">DS BẢO HIỂM</Nav.Link>
          <Nav.Link href="/bao-hiem-sap-het-han">CSKH BẢO HIỂM</Nav.Link>
          <Nav.Link href="/don-hang-bao-hiem">ĐƠN HÀNG BẢO HIỂM</Nav.Link>
          {/* <Nav.Link href="/tk-txe">APP TÀI XẾ</Nav.Link>
          <Nav.Link href="/tk-ch">APP CHỦ HÀNG</Nav.Link> */}
          <Nav.Link href="/phan-viec-chu-hang">PHÂN VIỆC CHỦ HÀNG</Nav.Link>
          {/* <Nav.Link href="/phan-viec-tai-xe">PHÂN VIỆC TÀI XẾ</Nav.Link> */}
          <Nav.Link href="/working-time">TH/GIAN LÀM VIỆC</Nav.Link>
          <Nav.Link href={`${config.webChatUrl}/chat`} target="_blank">
            CHAT
          </Nav.Link>
          <Nav.Link href="/quan-ly-file">QUẢN LÝ FILE</Nav.Link>
          <Nav.Item className="d-block d-lg-none">
            <hr />
          </Nav.Item>
          <Nav.Link href="/thong-ke" className="d-block d-lg-none">
            BÁO CÁO THỐNG KÊ CÔNG VIỆC
          </Nav.Link>
          {user.hasRole(CONSTANTS.PPKD_ROLES.XEM_THONG_KE_CUOC_GOI) && (
            <>
              <Nav.Link href="/tk-cuoc-goi" className="d-block d-lg-none">
                BÁO CÁO THỐNG KÊ CUỘC GỌI
              </Nav.Link>
            </>
          )}
          {(user.hasRole(CONSTANTS.SYSTEM_ROLES.QUAN_LY_GIO_CONG) || user.hasSalaryRole()) && (
            <>
              <Nav.Link href="/admin-working-time" className="d-block d-lg-none">
                QUẢN LÝ GIỜ LÀM VIỆC CỦA NHÂN VIÊN
              </Nav.Link>
            </>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
            <Nav.Link href="/app-phones" className="d-block d-lg-none">
              QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP
            </Nav.Link>
          )}
          {user.isAdmin() && (
            <Nav.Link href="/tk-web" className="d-block d-lg-none">
              QUẢN LÝ TÀI KHOẢN WEB
            </Nav.Link>
          )}
          <Nav.Link href="/phap-che" className="d-block d-lg-none">
            PHÁP CHẾ
          </Nav.Link>
          {(user.hasRole(CONSTANTS.SECRETARY_ROLES.QUAN_LY_KE_TOAN) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_GDDA) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTNB) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTT) ||
            user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_TPKD)) && (
            <Nav.Link href="/ke-toan-chi" className="d-block d-lg-none">
              TOÁN CHI
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.CHAT_ROLES.QUAN_LY_NHOM_CHAT_NOI_BO) && (
            <Nav.Link href="/quan-ly-nhom-chat" className="d-block d-lg-none">
              QUẢN LÝ NHÓM CHAT
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.CAI_DAT_PHAN_DON) && (
            <Nav.Link href="/cai-dat-phan-don" className="d-block d-lg-none">
              CÀI ĐẶT PHÂN CHIA ĐƠN HÀNG
            </Nav.Link>
          )}
          {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
            <Nav.Link href="/app-phones" className="d-block d-lg-none">
              QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP
            </Nav.Link>
          )}
          <Nav.Link href="/profile" className="d-block d-lg-none">
            TÀI KHOẢN
          </Nav.Link>
          <Nav.Link href="/logout" className="d-block d-lg-none">
            ĐĂNG XUẤT
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {user && !isEmpty(user.token) ? (
        <>
          <div className="user-profile-icon d-none d-lg-block" id="user">
            <span onClick={handleShowProfile}>
              <img src={UserProfileIcon} alt="profile" />
            </span>
          </div>
          <OverlayPanel ref={userProfileRef} appendTo={document.body} className="user-profile-overlay">
            <div className="menu-header wrap-text">{user.email}</div>
            <div className="menu-item">
              <NavLink to="/thong-ke" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-briefcase" />
                <span>BÁO CÁO THỐNG KÊ CÔNG VIỆC</span>
              </NavLink>
            </div>
            {user.hasRole(CONSTANTS.PPKD_ROLES.XEM_THONG_KE_CUOC_GOI) && (
              <div className="menu-item">
                <NavLink to="/tk-cuoc-goi" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-list" />
                  <span>BÁO CÁO THỐNG KÊ CUỘC GỌI</span>
                </NavLink>
              </div>
            )}
            {(user.hasRole(CONSTANTS.SYSTEM_ROLES.QUAN_LY_GIO_CONG) || user.hasSalaryRole()) && (
              <>
                <div className="menu-item">
                  <NavLink to="/admin-working-time" className="item-group" onClick={handleHideProfile}>
                    <i className="pi pi-clock" />
                    <span>QUẢN LÝ GIỜ LÀM VIỆC CỦA NHÂN VIÊN</span>
                  </NavLink>
                </div>
              </>
            )}
            {user.isAdmin() && (
              <div className="menu-item">
                <NavLink to="/tk-web" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-user" />
                  <span>QUẢN LÝ TÀI KHOẢN WEB</span>
                </NavLink>
              </div>
            )}
            <div className="menu-item">
              <NavLink to="/phap-che" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-lock" />
                <span>PHÁP CHẾ</span>
              </NavLink>
            </div>
            {(user.hasRole(CONSTANTS.SECRETARY_ROLES.QUAN_LY_KE_TOAN) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_GDDA) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTNB) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_KTT) ||
              user.hasRole(CONSTANTS.SECRETARY_ROLES.QUYEN_DUYET_CHI_TIEN_TPKD)) && (
              <div className="menu-item">
                <NavLink to="/ke-toan-chi" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-money-bill" />
                  <span>KẾ TOÁN CHI</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.CHAT_ROLES.QUAN_LY_NHOM_CHAT_NOI_BO) && (
              <div className="menu-item">
                <NavLink to="/quan-ly-nhom-chat" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-cloud" />
                  <span>QUẢN LÝ NHÓM CHAT</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.CAI_DAT_PHAN_DON) && (
              <div className="menu-item">
                <NavLink to="/cai-dat-phan-don" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-comments" />
                  <span>CÀI ĐẶT PHÂN CHIA ĐƠN HÀNG</span>
                </NavLink>
              </div>
            )}
            {user.hasRole(CONSTANTS.MANAGEMENT_ROLES.DUA_SDT_NHAN_VIEN_LEN_TREN_APP) && (
              <div className="menu-item">
                <NavLink to="/app-phones" className="item-group" onClick={handleHideProfile}>
                  <i className="pi pi-mobile" />
                  <span>QUẢN LÝ SỐ ĐIỆN THOẠI TRÊN APP</span>
                </NavLink>
              </div>
            )}
            <div className="menu-item">
              <NavLink to="/profile" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-user" />
                <span>TÀI KHOẢN</span>
              </NavLink>
            </div>
            <div className="menu-item">
              <NavLink to="/logout" className="item-group" onClick={handleHideProfile}>
                <i className="pi pi-sign-out" />
                <span>ĐĂNG XUẤT</span>
              </NavLink>
            </div>
          </OverlayPanel>
          <div className="chats-icon d-none d-lg-block">
            <span className="pi pi-comments" onClick={handleShowChatGroup}>
              {unread > 0 && <span className="chat-badges">{unread > 99 ? '99+' : unread}</span>}
            </span>
          </div>
          <OverlayPanel ref={chatGroupsRef} appendTo={document.body} className="chat-groups-overlay">
            <div className="flex flex-center chat-btn-tabs">
              <button
                className={classNames('p-button', {
                  'p-button-primary': chatTab === 'driver',
                  'p-button-secondary': chatTab !== 'driver',
                })}
                onClick={() => handleChatTabClick('driver')}
              >
                <p>TÀI XẾ</p>
                {unreadMessage.driver > 0 && (
                  <p className="unread-badge">{unreadMessage.driver > 99 ? '99+' : unreadMessage.driver}</p>
                )}
              </button>
              <button
                className={classNames('p-button', {
                  'p-button-primary': chatTab === 'ctv',
                  'p-button-secondary': chatTab !== 'ctv',
                })}
                onClick={() => handleChatTabClick('ctv')}
              >
                <p>CTV</p>
                {unreadMessage.ctv > 0 && (
                  <p className="unread-badge">{unreadMessage.ctv > 99 ? '99+' : unreadMessage.ctv}</p>
                )}
              </button>
              <button
                className={classNames('p-button', {
                  'p-button-primary': chatTab === 'internal',
                  'p-button-secondary': chatTab !== 'internal',
                })}
                onClick={() => handleChatTabClick('internal')}
              >
                <p>NỘI BỘ</p>
                {unreadMessage.internal > 0 && (
                  <p className="unread-badge">{unreadMessage.internal > 99 ? '99+' : unreadMessage.internal}</p>
                )}
              </button>
              <button
                className={classNames('p-button', {
                  'p-button-primary': chatTab === 'staff',
                  'p-button-secondary': chatTab !== 'staff',
                })}
                onClick={() => handleChatTabClick('staff')}
              >
                <p>NHÂN VIÊN</p>
                {unreadMessage.staff > 0 && (
                  <p className="unread-badge">{unreadMessage.staff > 99 ? '99+' : unreadMessage.staff}</p>
                )}
              </button>
            </div>
            <InfiniteList
              id="chat-overlay-groups"
              scrollable={true}
              direction="down"
              scrollBottom={false}
              items={chatGroups}
              onLoad={handleChatGroupLoad}
            >
              {chatGroups
                .filter((item: any) => item.chat_type === chatTab)
                .map((item) => {
                  const handleSelectGroup = () => {
                    // dispatch(setSelectedChat(item));
                    // handleHideProfile();
                    window.location.href = config.webChatUrl + '/chat?id=' + item.id;
                  };
                  return (
                    <li className={classNames('clearfix')} key={Math.random()} onClick={handleSelectGroup}>
                      <div className="list-item">
                        <div className="item-wrapper">
                          <div className="chat-avatar-group">
                            <img src={avatar} className="chat-avatar" alt="avatar" />
                          </div>
                          <div className="about">
                            <div className="name wrap-text">{item.name}</div>
                            <div className="last-message">
                              <i className="pi pi-user members-icon"></i> {item.last_sender_name}:{' '}
                              <span dangerouslySetInnerHTML={{ __html: item.last_message }}></span>
                            </div>
                          </div>
                        </div>
                        <div className="item-group">
                          {item.new_message > 0 && <small className="new-message">{item.new_message}</small>}
                        </div>
                        {item.unread > 0 && (
                          <div className="unread-count">{item.unread > 99 ? '99+' : item.unread}</div>
                        )}
                      </div>
                    </li>
                  );
                })}
            </InfiniteList>
          </OverlayPanel>
          <div className="notification-icon d-none d-lg-block">
            <span className="pi pi-bell" onClick={handleShowNotification}>
              {matchingOrderNoti.length > 0 && (
                <span className="chat-badges">{matchingOrderNoti.length > 99 ? '99+' : matchingOrderNoti.length}</span>
              )}
            </span>
          </div>
          <Dialog
            header="Thông báo"
            visible={visibleNotification}
            style={{ width: '90vw' }}
            onHide={handleHideNotification}
          >
            <div className="notification-container">
              {matchingOrderNoti.map((item: any, index: number) => {
                return (
                  <div className="notification-row">
                    <div>{index + 1}</div>
                    <div>
                      <p className="mt-10 mb-10 text-center">
                        <a href={'/don-hang-nhanh?ownerId=' + item.owner_id} target="_blank" rel="noreferrer">
                          {item.owner_id}
                        </a>{' '}
                        {item.orders.length > 0 ? `(${item.orders[0].transport_type}) ` : ''}
                        ĐANG PHÙ HỢP VỚI TÀI XẾ
                      </p>
                      {item.orders.map((item2: any) => {
                        return (
                          <p className="mt-10 mb-10 text-center">
                            <a href={'/tai-xe?search=' + item2.driver_id} target="_blank" rel="noreferrer">
                              {item2.driver_id}
                            </a>{' '}
                            <a href={'/xe-dat-hang?search=' + item2.order_id} target="_blank" rel="noreferrer">
                              ({item2.order_id})
                            </a>{' '}
                            {item2.full_name}
                            {`(${item2.vehicle_type || ''} ${item2.vehicle_container_type || ''} ${
                              item2.vehicle_container_weight || ''
                            })`}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </Dialog>

          <Dialog
            header="Đơn hàng phù hợp"
            visible={visibleMatchingOwner}
            style={{ width: '90vw' }}
            onHide={handleHideMatchingOwner}
          >
            <div className="notification-container">
              {!isEmpty(matchingOwner) && (
                <>
                  <div>
                    <p className="mt-10 mb-10 text-center">
                      <a href={'/don-hang-nhanh?ownerId=' + matchingOwner.owner_id} target="_blank" rel="noreferrer">
                        {matchingOwner.owner_id}
                      </a>{' '}
                      {matchingOwner.orders.length > 0 ? `(${matchingOwner.orders[0].transport_type}) ` : ''}
                      ĐANG PHÙ HỢP VỚI TÀI XẾ
                    </p>
                    {matchingOwner.orders.map((item2: any) => {
                      return (
                        <p className="mt-10 mb-10 text-center">
                          <a href={'/tai-xe?search=' + item2.driver_id} target="_blank" rel="noreferrer">
                            {item2.driver_id}
                          </a>{' '}
                          <a href={'/xe-dat-hang?search=' + item2.order_id} target="_blank" rel="noreferrer">
                            ({item2.order_id})
                          </a>{' '}
                          {item2.full_name}
                          {`(${item2.vehicle_type || ''} ${item2.vehicle_container_type || ''} ${
                            item2.vehicle_container_weight || ''
                          })`}
                        </p>
                      );
                    })}
                  </div>
                  <hr />
                  {matchingOwner.owner && (
                    <div>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Đơn hàng:
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.deliver_route}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Mã đơn hàng :
                        </Col>
                        <Col xs={12} sm={6} className="text-danger">
                          {matchingOwner.owner.owner_id}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Ngày đăng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {moment(matchingOwner.owner.created_at).format(CONSTANTS.DATE_FORMAT.LONG)}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Ngày bốc hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.nh_date}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Ngày trả hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.gh_date}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Vận chuyển :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.kieu_hh || 'Tất cả'}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Loại hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.loai_hh}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Yêu cầu xe :
                        </Col>
                        <Col xs={12} sm={6}>
                          {getTransportType(matchingOwner.owner)}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Cách nhận hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.transport_detail}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Điểm nhận hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          <div>
                            {!isEmpty(matchingOwner.owner.nh_address) ? `${matchingOwner.owner.nh_address} - ` : ''}
                            {matchingOwner.owner.nh_district} - {matchingOwner.owner.owner_from}
                          </div>
                          {matchingOwner.owner.transport_detail_1 >= 2 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.nh_address_2)
                                ? `${matchingOwner.owner.nh_address_2} - `
                                : ''}
                              {matchingOwner.owner.nh_district_2} - {matchingOwner.owner.owner_from_2}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_1 >= 3 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.nh_address_3)
                                ? `${matchingOwner.owner.nh_address_3} - `
                                : ''}
                              {matchingOwner.owner.nh_district_3} - {matchingOwner.owner.owner_from_3}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_1 >= 4 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.nh_address_4)
                                ? `${matchingOwner.owner.nh_address_4} - `
                                : ''}
                              {matchingOwner.owner.nh_district_4} - {matchingOwner.owner.owner_from_4}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_1 >= 5 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.nh_address_5)
                                ? `${matchingOwner.owner.nh_address_5} - `
                                : ''}
                              {matchingOwner.owner.nh_district_5} - {matchingOwner.owner.owner_from_5}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Điểm trả hàng :
                        </Col>
                        <Col xs={12} sm={6}>
                          <div>
                            {!isEmpty(matchingOwner.owner.gh_address) ? `${matchingOwner.owner.gh_address} - ` : ''}
                            {matchingOwner.owner.gh_district} - {matchingOwner.owner.owner_to}
                          </div>
                          {matchingOwner.owner.transport_detail_2 >= 2 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.gh_address_2)
                                ? `${matchingOwner.owner.gh_address_2} - `
                                : ''}
                              {matchingOwner.owner.gh_district_2} - {matchingOwner.owner.owner_to_2}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_2 >= 3 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.gh_address_3)
                                ? `${matchingOwner.owner.gh_address_3} - `
                                : ''}
                              {matchingOwner.owner.gh_district_3} - {matchingOwner.owner.owner_to_3}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_2 >= 4 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.gh_address_4)
                                ? `${matchingOwner.owner.gh_address_4} - `
                                : ''}
                              {matchingOwner.owner.gh_district_4} - {matchingOwner.owner.owner_to_4}
                            </div>
                          )}
                          {matchingOwner.owner.transport_detail_2 >= 5 && (
                            <div>
                              {!isEmpty(matchingOwner.owner.gh_address_5)
                                ? `${matchingOwner.owner.gh_address_5} - `
                                : ''}
                              {matchingOwner.owner.gh_district_5} - {matchingOwner.owner.owner_to_5}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Kích thước hàng hóa :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.transport_size}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Số lượng :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.ch_weight} {matchingOwner.owner.unit}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Cước trên 1 đơn vị :
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormattedNumber
                            value={Number(matchingOwner.owner.cx_don_gia)}
                            maximumFractionDigits={0}
                            minimumFractionDigits={0}
                          />
                          <small> đ</small>
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Cước trên 1 chuyến :
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormattedNumber
                            value={Number(matchingOwner.owner.cx_thanh_tien)}
                            maximumFractionDigits={0}
                            minimumFractionDigits={0}
                          />
                          <small> đ</small>
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Hình thức thanh toán :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.payment_type}
                        </Col>
                      </Row>
                      <Row className="mt-10">
                        <Col xs={12} sm={4} smOffset={2} className="label">
                          Lưu ý :
                        </Col>
                        <Col xs={12} sm={6}>
                          {matchingOwner.owner.note}
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              )}
            </div>
          </Dialog>
          <Dialog
            header="Đơn hàng phù hợp"
            visible={visibleMatchingOrder}
            style={{ width: '90vw' }}
            onHide={handleHideMatchingOrder}
          >
            <div className="notification-container">
              {!isEmpty(matchingOrder) && (
                <>
                  <div>
                    <p className="mt-10 mb-10 text-center">
                      <a href={'/tai-xe?search=' + matchingOrder.driver_id} target="_blank" rel="noreferrer">
                        {matchingOrder.driver_id}
                      </a>{' '}
                      <a href={'/xe-dat-hang?search=' + matchingOrder.order_id} target="_blank" rel="noreferrer">
                        ({matchingOrder.order_id})
                      </a>{' '}
                      {matchingOrder.full_name} ĐANG PHÙ HỢP VỚI ĐƠN HÀNG
                    </p>
                    {matchingOrder.owners?.map((item2: any) => {
                      return (
                        <p className="mt-10 mb-10 text-center">
                          <a href={'/don-hang-nhanh?ownerId=' + item2.owner_id} target="_blank" rel="noreferrer">
                            {item2.owner_id}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Dialog>
        </>
      ) : (
        <>
          <NavLink to="/login" className="login-btn fsi-14 mr-20">
            <i className="pi pi-sign-in" />
            <span className="pl-8">Đăng nhập</span>
          </NavLink>
        </>
      )}
    </Navbar>
  );
}

export default withRouter(Header);
