const CONSTANTS = {
  STORAGE_KEY: {
    DRIVER_USER: 'driver-user',
    REDIRECT: 'redirect',
    LOGIN_INFO: 'login-info',
  },
  DATE_FORMAT: {
    LONG: 'DD/MM/YYYY HH:mm:ss',
    SHORT: 'DD/MM/YYYY',
    TIMEZONE: 'Asia/Ho_Chi_Minh',
  },
  TIME_FORMAT: 'HH:mm:ss',
  // ROLES: {
  //   1: 'DS tài xế',
  //   2: 'Xe đặt hàng',
  //   3: 'DS chủ hàng',
  //   4: 'Chủ đặt hàng',
  //   5: 'Vận hành đơn hàng',
  //   6: 'Đơn hàng nhanh',
  //   7: 'Hồ sơ tài xế',
  //   8: 'Quản trị hệ thống',
  //   9: 'DS chành xe',
  //   10: 'Chành đặt hàng',
  //   11: 'Chỉnh sửa hóa đơn',
  //   12: 'Quản lý tài khoản ứng dụng',
  //   13: 'Chỉnh sửa dữ liệu sau khi lên đơn',
  //   14: 'Thanh toán đơn hàng',
  //   15: 'Thanh toán chuyển khoản',
  //   16: 'Quản lý số điện thoại trên APP',
  //   17: 'Xem thông tin tất cả đơn hàng',
  //   18: 'Vận chuyển đơn hàng',
  //   19: 'DS bảo hiểm',
  //   20: 'Đơn hàng bảo hiểm',
  //   21: 'Cập nhật thông tin tài xế',
  //   22: 'Duyệt hủy tương tác',
  //   23: 'Quản lý giờ công',
  //   24: 'Phân việc',
  //   25: 'Chỉnh sửa Mã giới thiệu',
  //   26: 'Xem mã chủ hàng của tài xế',
  //   27: 'Xem thống kê cuộc gọi',
  //   28: 'Cài đặt ngày thông báo bảo hiểm',
  //   29: 'Quản lý bảo hiểm',
  //   30: 'Duyệt đơn hàng bảo hiểm',
  //   31: 'Quản lý pháp chế',
  //   32: 'Quản lý nhóm chat NỘI BỘ',
  //   33: 'Quản lý file',
  //   34: 'Quản lý kế toán',
  //   35: 'GDDA',
  //   36: 'TPKD',
  //   37: 'KT TRƯỞNG',
  //   38: 'KT CHI TIỀN',
  // },
  SYSTEM_ROLES: {
    QUAN_TRI_HE_THONG: 'QUẢN TRỊ HỆ THỐNG',
    BAM_THANH_TOAN_CUA_VAN_HANH_DON_HANG: 'BẤM THANH TOÁN CỦA VẬN HÀNH ĐƠN HÀNG',
    QUAN_LY_GIO_CONG: 'QUẢN LÝ GIỜ CÔNG',
  },
  CHAT_ROLES: {
    QUAN_LY_NHOM_CHAT_NOI_BO: 'QUẢN LÝ NHÓM CHAT NỘI BỘ',
  },
  STAFF_ROLES: {
    DS_TAI_XE: 'DS TÀI XẾ',
    XE_DAT_HANG: 'XE ĐẶT HÀNG',
    DS_CHU_HANG: 'DS CHỦ HÀNG',
    CHU_DAT_HANG: 'CHỦ ĐẶT HÀNG',
    DS_CHANH_XE: 'DS CHÀNH XE',
    CHANH_DAT_HANG: 'CHÀNH ĐẶT HÀNG',
    VAN_HANH_DON_HANG: 'VẬN HÀNH ĐƠN HÀNG',
    CHINH_SUA_HOP_DONG_DON_HANG: 'CHỈNH SỬA HỢP ĐỒNG ĐƠN HÀNG',
    LAM_LENH_THANH_TOAN_CHUYEN_KHOAN: 'LÀM LỆNH THANH TOÁN CHUYỂN KHOẢN',
    DS_BAO_HIEM: 'DS BẢO HIỂM',
    DON_HANG_BAO_HIEM: 'ĐƠN HÀNG BẢO HIỂM',
  },
  APP_ROLES: {
    TRUY_CAP_APP_TAI_XE: 'TRUY CẬP APP TÀI XẾ',
    TRUY_CAP_APP_CHU_HANG: 'TRUY CẬP APP CHỦ HÀNG',
  },
  PPKD_ROLES: {
    DON_HANG_NHANH: 'ĐƠN HÀNG NHANH',
    PHAN_VIEC_TAI_XE: 'PHÂN VIỆC TÀI XẾ',
    PHAN_VIEC_CHU_HANG: 'PHÂN VIỆC CHỦ HÀNG',
    XEM_THONG_KE_CUOC_GOI: 'XEM THỐNG KÊ CUỘC GỌI',
  },
  MANAGEMENT_ROLES: {
    CHINH_SUA_DU_LIEU_SAU_KHI_VAN_HANH_DON_HANG: 'CHỈNH SỬA DỮ LIỆU SAU KHI VẬN HÀNH ĐƠN HÀNG',
    DUA_SDT_NHAN_VIEN_LEN_TREN_APP: 'ĐƯA SĐT NHÂN VIÊN LÊN TRÊN APP',
    XEM_THONG_TIN_DAY_DU_KHI_VAN_HANH_DON_HANG: 'XEM THÔNG TIN ĐẦY ĐỦ KHI VẬN HÀNH ĐƠN HÀNG',
    BAM_NUT_VAN_CHUYEN_DON_HANG_TREN_VAN_HANH_DON_HANG: 'BẤM NÚT VẬN CHUYỂN ĐƠN HÀNG TRÊN VẬN HÀNH ĐƠN HÀNG',
    CHINH_SUA_THONG_TIN_TAI_XE: 'CHỈNH SỬA THÔNG TIN TÀI XẾ',
    CHINH_SUA_THONG_TIN_CHU_HANG: 'CHỈNH SỬA THÔNG TIN CHỦ HÀNG',
    DUYET_HUY_TUONG_TAC_TAI_XE: 'DUYỆT HỦY TƯƠNG TÁC TÀI XẾ',
    CHINH_SUA_MA_GIOI_THIEU_PHAN_TAI_XE: 'CHỈNH SỬA MÃ GIỚI THIỆU PHẦN TÀI XẾ',
    XEM_MA_CHEO_CHU_HANG_VA_TAI_XE: 'XEM MÃ CHÉO CHỦ HÀNG VÀ TÀI XẾ',
    QUAN_LY_FILE: 'QUẢN LÝ FILE',
    XEM_TAT_CA_DON_XE_DAT_HANG: 'XEM TẤT CẢ ĐƠN XE ĐẶT HÀNG',
    CAI_DAT_PHAN_DON: 'CÀI ĐẶT PHÂN ĐƠN',
  },
  INSURANCE_ROLES: {
    CAI_DAT_NGAY_GAN_HET_HAN_BAO_HIEM: 'CÀI ĐẶT NGÀY GẦN HẾT HẠN BẢO HIỂM',
    QUAN_LY_BAO_HIEM: 'QUẢN LÝ BẢO HIỂM',
    DUYET_DON_HANG_BAO_HIEM: 'DUYỆT ĐƠN HÀNG BẢO HIỂM',
  },
  SECRETARY_ROLES: {
    QUAN_LY_KE_TOAN: 'QUẢN LÝ KẾ TOÁN',
    QUYEN_DUYET_CHI_TIEN_GDDA: 'QUYỀN DUYỆT CHI TIỀN ( GDDA )',
    QUYEN_DUYET_CHI_TIEN_TPKD: 'QUYỀN DUYỆT CHI TIỀN ( TPKD )',
    QUYEN_DUYET_CHI_TIEN_KTT: 'QUYỀN DUYỆT CHI TIỀN ( KTT )',
    QUYEN_DUYET_CHI_TIEN_KTNB: 'QUYỀN DUYỆT CHI TIỀN ( KTNB )',
  },
  REGULATION_ROLES_2: {
    QUAN_LY_PHAP_CHE: 'QUẢN LÝ PHÁP CHẾ',
    QUYEN_DUYET_VI_PHAM_PPPC: 'QUYỀN DUYỆT VI PHẠM ( PPPC )',
    QUYEN_DUYET_VI_PHAM_PPKD: 'QUYỀN DUYỆT VI PHẠM ( PPKD )',
    QUYEN_DUYET_VI_PHAM_TPKD: 'QUYỀN DUYỆT VI PHẠM ( TPKD )',
    QUYEN_DUYET_VI_PHAM_GDDA: 'QUYỀN DUYỆT VI PHẠM ( GDDA )',
  },
  SALARY_ROLES_2: {
    DUYET_GIO_LAM_VIEC: 'DUYỆT GIỜ LÀM VIỆC',
    XEM_BAO_CAO_GIO_VA_NGHI_PHEP: 'XEM BÁO CÁO GIỜ VÀ NGHỈ PHÉP',
    XEM_BANG_CHAM_CONG: 'XEM BẢNG CHẤM CÔNG',
    XEM_KPI_NHAN_HANG: 'XEM KPI NHẬN HÀNG',
    XEM_KPI_VAN_HANH: 'XEM KPI VẬN HÀNH',
    XEM_BANG_LUONG: 'XEM BẢNG LƯƠNG',
    CHINH_SUA_BANG_LUONG: 'CHỈNH SỬA BẢNG LƯƠNG',
  },
  PAYMENT_ROLES: {
    QUAN_LY_THANH_TOAN_1: 'QUẢN LÝ THANH TOÁN 1',
    QUAN_LY_THANH_TOAN_2: 'QUẢN LÝ THANH TOÁN 2',
    QUAN_LY_THANH_TOAN_3: 'QUẢN LÝ THANH TOÁN 3',
    NHAN_TIEN_CHU_HANG_THANH_TOAN: 'NHẬN TIỀN CHỦ HÀNG THANH TOÁN',
    HUY_LENH_NHAN_TIEN: 'HỦY LỆNH NHẬN TIỀN',
  },
  COLLATERAL_ROLES: {
    PHO_PHONG_KINH_DOANH: 'PHÓ PHÒNG KINH DOANH',
    TRUONG_PHONG_KINH_DOANH: 'TRƯỞNG PHÒNG KINH DOANH',
    KE_TOAN: 'KẾ TOÁN',
  },
  KIEM_SOAT_DON_ROLES: {
    GHIM_DON_TRA_SOAT: 'GHIM ĐƠN TRA SOÁT',
    AN_DON_HUY_TRA_SOAT: 'ẨN ĐƠN HỦY TRA SOÁT',
    QUAN_LY_DUYET_AN_DON_2: 'QUẢN LÝ DUYỆT ẨN ĐƠN 2',
    QUAN_LY_DUYET_AN_DON_3: 'QUẢN LÝ DUYỆT ẨN ĐƠN 3',
  },
  APP_PERMISSION_ROLES: {
    XEM_DANH_BA: 'XEM DANH BẠ',
    DON_HANG_NHANH: 'ĐƠN HÀNG NHANH',
  },
  SALARY_ROLES: {
    1: 'DUYỆT GIỜ LÀM VIỆC',
    2: 'XEM BÁO CÁO GIỜ VÀ NGHỈ PHÉP',
    3: 'XEM BẢNG CHẤM CÔNG',
    4: 'XEM KPI NHẬN HÀNG',
    5: 'XEM KPI VẬN HÀNH',
    6: 'XEM BẢNG LƯƠNG',
    7: 'CHỈNH SỬA BẢNG LƯƠNG',
  },
  REGULATION_ROLES: {
    1: 'PPPC',
    2: 'PPKD',
    3: 'TPKD',
    4: 'GDDA',
  },
  SOCKET_EVENT: {
    MESSAGE_COUNT: 'message-count',
    NEW_MESSAGE: 'new-message',
    NEW_INSTANT: 'new-instant',
    NEW_OWNER_ASSIGNMENT: 'new-owner-assignment',
    NEW_MATCHING_OWNER: 'new-matching-owner',
    NEW_MATCHING_ORDER: 'new-matching-order',
    UPDATE_SUGGESTION: 'update-suggestion',
    UPDATE_MESSAGE: 'update-message',
  },
  LOCATION_DURATION: 3,
  RESERVED_TIME: 10,
  LOCKED_TIME_FOR_GROUP: 5,
  PAYMENT_TYPE: {
    COMPANY: 'CÔNG TY',
  },
};

export default CONSTANTS;
