import { isEmpty } from 'lodash';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CONSTANTS from '../constants/constant';
import AccountService from '../services/Account';
import { calculateWorkingHours } from '../store/user/utils';
import { useUser } from '../store/hooks/hooks';

// import FingerprintIcon from '../statics/images/checkin-new.gif';
import CheckoutIcon from '../statics/images/checkout.gif';
import './CheckinCheckout.scss';

interface Props {
  ignorePage?: string;
  onSuccess?: () => void;
}

export default function CheckinCheckout(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isCheckIn, setCheckIn] = useState<boolean>(false);

  const location = useLocation();
  const user = useUser();

  const handleDialogHide = () => {
    setMessage('');
    setCheckIn(false);
    setVisible(false);
  };

  const handleOKClick = () => {
    handleDialogHide();
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const handleCheckInOut = async () => {
    setLoading(true);
    const result = await AccountService.checkInOut();
    console.log(result);
    if (result.success && result.data.id > 0) {
      if (result.data.start_time !== result.data.end_time) {
        setMessage(
          `<div>Bạn đã CHECK OUT thành công lúc ${moment(result.data.end_time).format(
            CONSTANTS.DATE_FORMAT.LONG,
          )}</div><div><b>TỔNG SỐ GIỜ LÀM VIỆC ${calculateWorkingHours(
            result.data.start_time,
            result.data.start_time,
            result.data.end_time,
          ).toFixed(1)}</b></div>`,
        );
      } else {
        setMessage(`Bạn đã CHECK IN thành công lúc ${moment(result.data.end_time).format(CONSTANTS.DATE_FORMAT.LONG)}`);
      }
      if (isCheckIn && props.onSuccess) {
        props.onSuccess();
      }
    } else {
      setMessage(result.message);
    }
    setLoading(false);
    setVisible(true);
  };

  useEffect(() => {
    const fetchCheckInOut = async () => {
      const result = await AccountService.fetchCheckInOut();
      if (result.success) {
        setCheckIn(isEmpty(result.data));
      }
    };
    fetchCheckInOut();
  }, []);

  return (isEmpty(props.ignorePage) || (props.ignorePage && location.pathname.indexOf(props.ignorePage) < 0)) &&
    (isCheckIn || location.pathname.indexOf('/working-time') >= 0) &&
    user.email !== 'ADMIN1' &&
    user.email !== 'ADMIN2' &&
    moment().get('hours') >= 6 ? (
    <div className="fingerprint-wrapper" title="Check In">
      {isLoading ? (
        <div>
          <i className="pi pi-spin pi-spinner" />
        </div>
      ) : isCheckIn ? (
        <>
          <span>CHECK IN</span>
          <img src={CheckoutIcon} alt="fingerprint" onClick={handleCheckInOut} />
        </>
      ) : (
        <>
          <span>CHECK OUT</span>
          <img src={CheckoutIcon} alt="fingerprint" onClick={handleCheckInOut} />
          {/* <img src={FingerprintIcon} alt="fingerprint" onClick={handleCheckInOut} /> */}
        </>
      )}
      <Dialog
        appendTo={document.body}
        header="Thông báo"
        visible={visible}
        style={{ width: '450px' }}
        onHide={handleDialogHide}
        maximizable={false}
        className="confirm-dialog"
        closable={true}
      >
        <div className="text-center" dangerouslySetInnerHTML={{ __html: message }}></div>
        <div className="flex-center mt-8">
          <Button label="OK" className="p-button-primary p-button-sm" onClick={handleOKClick} />
        </div>
      </Dialog>
    </div>
  ) : (
    <></>
  );
}
