import io from 'socket.io-client';

import { config } from '../config';
import { getUserStorage } from '../store/user/utils';

let socketConnection: any;

function initSocketConnection() {
  const user = getUserStorage();
  socketConnection = io(`${config.socketServerUrl}?token=${user.token}&app=web`, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
    transports: ['websocket'],
    upgrade: false,
  });

  return socketConnection;
}

function getSocketConnection() {
  return socketConnection || initSocketConnection();
}

export { getSocketConnection };
