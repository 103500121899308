import { SocketActionTypes, SOCKET_IO_CONNECT, SOCKET_IO_DISCONNECT } from './types';

export function connectSocket(): SocketActionTypes {
  return {
    type: SOCKET_IO_CONNECT,
  };
}

export function disconnectSocket(): SocketActionTypes {
  return {
    type: SOCKET_IO_DISCONNECT,
  };
}
