import axios from 'axios';

const ACCOUNT_API = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  ADMIN_LOGOUT: '/auth/admin-logout',
  REGISTER: '/auth/register',
  UPDATE: '/auth/update',
  UPDATE_PASSWORD: '/auth/update-password',
  REMOVE: '/auth/remove',
  ACCOUNTS: '/auth/accounts',
  MY_IP: '/auth/myip',
  LOGS: '/auth/audit-logs',
  APP_USERS: '/users_app',
  CHECK_IN_OUT: '/auth/check-in-out',
};

async function login(values: any) {
  const { data } = await axios.post(ACCOUNT_API.LOGIN, values);
  return data;
}

async function logout() {
  const { data } = await axios.put(ACCOUNT_API.LOGOUT);
  return data;
}

async function adminLogout(id: number) {
  const { data } = await axios.put(`${ACCOUNT_API.ADMIN_LOGOUT}/${id}`);
  return data;
}

async function register(values: any) {
  const { data } = await axios.post(ACCOUNT_API.REGISTER, values);
  return data;
}

async function update(values: any) {
  const { data } = await axios.put(ACCOUNT_API.UPDATE, values);
  return data;
}

async function fetchUsers(withoutHidden: boolean = false, withManagerFilter: boolean = false) {
  const { data } = await axios.get(ACCOUNT_API.ACCOUNTS, {
    params: {
      withoutHidden,
      withManagerFilter,
    },
  });
  return data;
}

async function fetchMyIP() {
  const { data } = await axios.get(ACCOUNT_API.MY_IP);
  return data;
}

async function removeUser(id: number) {
  const { data } = await axios.delete(`${ACCOUNT_API.REMOVE}/${id}`);
  return data;
}

async function getLogs(limit: number, offset: number, total: number, search: string) {
  const { data } = await axios.get(ACCOUNT_API.LOGS, {
    params: {
      limit,
      offset,
      total,
      search,
    },
  });
  return data;
}

async function fetchAppUsers(app: string) {
  const { data } = await axios.get(`${ACCOUNT_API.APP_USERS}/accounts`, {
    params: {
      app,
    },
  });
  return data;
}

async function updateAppUser(payload: any) {
  const { data } = await axios.put(`${ACCOUNT_API.APP_USERS}/update`, payload);
  return data;
}

async function removeAppUser(id: number) {
  const { data } = await axios.delete(`${ACCOUNT_API.APP_USERS}/remove/${id}`);
  return data;
}

async function fetchAppManagers() {
  const { data } = await axios.get(`${ACCOUNT_API.APP_USERS}/managers`);
  return data;
}

async function updateManager(payload: any) {
  const { data } = await axios.put(`${ACCOUNT_API.APP_USERS}/managers`, payload);
  return data;
}

async function getSupporters() {
  const { data } = await axios.get(`${ACCOUNT_API.APP_USERS}/supporters`);
  return data;
}

async function getAppVersions() {
  const { data } = await axios.get(`${ACCOUNT_API.APP_USERS}/app-versions`);
  return data;
}

async function updatePassword(payload: any) {
  const { data } = await axios.put(ACCOUNT_API.UPDATE_PASSWORD, payload);
  return data;
}

async function fetchCheckInOut() {
  const { data } = await axios.get(ACCOUNT_API.CHECK_IN_OUT);
  return data;
}

async function checkInOut() {
  const { data } = await axios.post(ACCOUNT_API.CHECK_IN_OUT);
  return data;
}

const AccountService = {
  login,
  logout,
  adminLogout,
  register,
  update,
  fetchUsers,
  removeUser,
  getLogs,
  fetchAppUsers,
  updateAppUser,
  removeAppUser,
  fetchAppManagers,
  updateManager,
  getSupporters,
  getAppVersions,
  fetchMyIP,
  updatePassword,
  fetchCheckInOut,
  checkInOut,
};

export default AccountService;
