import Lightbox from 'react-awesome-lightbox';
import React from 'react';
import { isEmpty } from 'lodash';

interface Props {
  urls: any[];
  startIndex: number;
  onHide: () => void;
}

export default function ImageFullScreen(props: Props) {
  return !isEmpty(props.urls) ? (
    <Lightbox images={props.urls} startIndex={props.startIndex || 0} onClose={props.onHide} />
  ) : (
    <></>
  );
}
